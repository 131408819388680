import BookingInterface from '@/interfaces/booking.interface';
import dayjs from 'dayjs';

export default class Booking implements BookingInterface {
  id: number;

  folio: string;

  agency: string;

  auto: string;

  birthdate: Date;

  dateIn: Date;

  dateOut: Date;

  email: string;

  identification: string;

  lastname: string;

  name: string;

  phone: string;

  timeIn: string;

  timeOut: string;

  status: boolean;

  authorization: boolean;

  fuel: string;

  sign: string | undefined;

  constructor(json?: Partial<BookingInterface>) {
    this.id = json?.id ?? 0;
    this.folio = json?.folio ?? '';
    this.agency = json?.agency ?? '';
    this.auto = json?.auto ?? '';
    this.birthdate = json?.birthdate ?? new Date();
    this.dateIn = json?.dateIn ?? new Date();
    this.dateOut = json?.dateOut ?? new Date();
    this.email = json?.email ?? '';
    this.identification = json?.identification ?? '';
    this.lastname = json?.lastname ?? '';
    this.name = json?.name ?? '';
    this.phone = json?.phone ?? '';
    this.timeIn = json?.timeIn ?? '';
    this.timeOut = json?.timeOut ?? '';
    this.status = json?.status ?? false;
    this.authorization = json?.authorization ?? false;
    this.fuel = json?.fuel ?? '';
    this.sign = json?.sign ?? undefined;
  }

  static fromJSON(json: any): Booking {
    return new Booking({
      id: parseInt(json.id, 10),
      folio: json.folio,
      agency: json.agency,
      auto: json.auto,
      birthdate: json.birthdate ? dayjs(json.birthdate).toDate() : new Date(),
      dateIn: json.dateIn ? dayjs(json.dateIn).toDate() : new Date(),
      dateOut: json.dateOut ? dayjs(json.dateOut).toDate() : new Date(),
      email: json.email,
      identification: json.identification,
      lastname: json.lastname,
      name: json.name,
      phone: json.phone,
      timeIn: json.timeIn,
      timeOut: json.timeOut,
      status: json.status,
      authorization: json.authorization,
      fuel: json.fuel,
      sign: json.sign,
    });
  }

  toJson(): BookingInterface {
    return {
      id: this.id,
      folio: this.folio,
      agency: this.agency,
      auto: this.auto,
      birthdate: this.birthdate,
      dateIn: this.dateIn,
      dateOut: this.dateOut,
      email: this.email,
      identification: this.identification,
      lastname: this.lastname,
      name: this.name,
      phone: this.phone,
      timeIn: this.timeIn,
      timeOut: this.timeOut,
      status: this.status,
      authorization: this.authorization,
      fuel: this.fuel,
      sign: this.sign,
    };
  }
}
