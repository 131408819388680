<template>
  <v-container>
    <v-sheet border="md"
             class="pa-6 mx-auto mb-5">
      <h4 class="text-h5 font-weight-bold mb-4 text-uppercase">Información de reserva</h4>

      <v-container>
        <v-row>
          <v-col cols="6" md="3">
            <span class="font-weight-black">Salida</span>
            <span class="d-block">{{ dateOut }}</span>
          </v-col>

          <v-col cols="6" md="3">
            <span class="font-weight-black">Devolución</span>
            <span class="d-block">{{ dateIn }}</span>
          </v-col>

          <v-col cols="6" md="3">
            <span class="font-weight-black">Oficina de salida</span>
            <span class="d-block">{{ booking.agency }}</span>
          </v-col>

          <v-col cols="6" md="3">
            <span class="font-weight-black">Oficina de devolución</span>
            <span class="d-block">{{ booking.agency }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="3">
            <span class="font-weight-black">Patente</span>
            <span class="d-block">{{ booking.auto }}</span>
          </v-col>

          <v-col cols="6" md="3">
            <span class="font-weight-black">Combustible</span>
            <span class="d-block">Full</span>
          </v-col>

          <v-col cols="6" md="3">
            <span class="font-weight-black">Kilometraje</span>
            <span class="d-block">17 kms</span>
          </v-col>
        </v-row>
      </v-container>

    </v-sheet>

    <v-sheet border="md"
             class="pa-6 mx-auto mb-5">
      <h4 class="text-h5 font-weight-bold mb-4 text-uppercase">
        Valor estimado a pagar
        <span class="d-block text-subtitle-2 font-weight-bold">* Reserva ficticia *</span>
      </h4>

      <p class="mb-5">
        **El valor estimado a pagar informado, está sujeto a posibles adicionales que ocurran
        durante el arriendo.
      </p>
      <p>
        **Los cobros por utilización de las autopistas concesionadas urbanas e interurbanas
        (Pasajes/Free Flow), durante todo el periodo de arriendo que el vehículo esta a su cargo,
        seran cargados al final del arriendo en su tarjeta de credito y/o cuenta corporativa,
        de acuerdo al consumo que registre el vehículo arrendado, mas el respectivo fee de
        administración.
      </p>
    </v-sheet>

    <v-sheet border="md"
             class="pa-6 mx-auto">
      <h4 class="text-h5 font-weight-bold mb-4 text-uppercase">Condiciones Generales</h4>

      <v-container>
        <v-virtual-scroll
          :height="400"
          :items="generalConditions"
          class="px-10"
        >
          <template v-slot:default="{ item }">
            <h3 class="pb-3">{{ item.title }}</h3>
            <div v-for="(el, key) of item.apartado.parrafo"
                 v-bind:key="key"
                 class="mb-5">
              <p class="text-justify text-caption">{{ el.texto }}</p>

              <ol class="px-15">
                <li class="text-justify text-caption"
                    v-for="(li, keyLi) of el.listado"
                    v-bind:key="keyLi">
                  {{ li }}
                </li>
              </ol>
            </div>

          </template>
        </v-virtual-scroll>

      </v-container>
    </v-sheet>

    <v-row class="my-10">
      <v-col md="6" />
      <v-col md="3">
        <v-btn rounded="xl" size="x-large" :disabled="loading" block v-on:click="cancel">
          Cancelar
        </v-btn>
      </v-col>
      <v-col md="3">
        <v-btn color="primary"
               rounded="xl"
               size="x-large"
               :loading="loading"
               v-on:click="sign"
               block>
          Firmar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import useContractStore from '@/store/contract.store';
import Booking from '@/models/Booking';
import dayjs from 'dayjs';

const router = useRouter();
const contractStore = useContractStore();
const booking = ref(new Booking());
const loading = ref<boolean>(false);
const generalConditions = ref([]);
const dateOut = ref('');
const dateIn = ref('');

function cancel(): void {
  contractStore.$reset();
  router.back();
}

async function sign(): Promise<void> {
  await router.push({ name: 'BookingSignContract' });
}

onBeforeMount(() => {
  if (!contractStore.generalConditions) {
    cancel();
  }

  if (contractStore.generalConditions) {
    generalConditions.value = contractStore.generalConditions;
    booking.value = contractStore.booking;

    let [hours, minutes] = booking.value.timeOut;
    dateOut.value = dayjs(booking.value.dateOut).set('hours', hours).set('minutes', minutes)
      .format('DD-MM-YYYY hh:mm a');

    [hours, minutes] = booking.value.timeIn;
    dateIn.value = dayjs(booking.value.dateIn).set('hours', hours).set('minutes', minutes)
      .format('DD-MM-YYYY hh:mm a');
  }
});
</script>
