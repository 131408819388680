<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import useBookingStore from '@/store/booking.store';
import useContractStore from '@/store/contract.store';
import Booking from '@/models/Booking';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

const bookingStore = useBookingStore();
const contractStore = useContractStore();
const router = useRouter();

const booking = ref(new Booking());

function cancel(): void {
  router.push({ name: 'BookingFind' });
}

onBeforeMount(() => {
  if (!contractStore.booking) {
    cancel();
  }

  if (contractStore.booking) {
    booking.value = contractStore.booking;
  }
});

onBeforeUnmount(() => {
  bookingStore.$reset();
  contractStore.$reset();
});

onBeforeRouteLeave(async (to, from, next) => {
  if (to.name === 'BookingSignContract') {
    next(false);
  }

  next();
});
</script>

<template>
  <v-container>
      <v-sheet
        class="pa-4 text-center mx-auto"
        elevation="12"
        max-width="600"
        rounded="lg"
        width="100%"
      >
        <v-icon
          class="mb-5"
          color="success"
          icon="mdi-check-circle"
          size="112"
        ></v-icon>

        <h3 class="text-h5 mb-6">Contrato N° {{ booking.folio }}</h3>
        <h2 class="text-h5 mb-6">Felicidades</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          Su contrato se ha creado con exito. recuerde que esto es una demo, este contrato no
          tiene ninguna validez legal.
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-end">
          <v-btn
            class="text-none"
            variant="flat"
            width="90"
            rounded
            v-on:click="cancel"
          >
            Finalizar
          </v-btn>
        </div>
      </v-sheet>
  </v-container>
</template>

<style scoped>

</style>
