<script setup lang="ts">
import useContractStore from '@/store/contract.store';
import { useRouter } from 'vue-router';
import { onBeforeMount, ref } from 'vue';
import Vue3Signature from 'vue3-signature';
import Booking from '@/models/Booking';
import useBookingStore from '@/store/booking.store';

const router = useRouter();
const bookingStore = useBookingStore();
const contractStore = useContractStore();
const loading = ref<boolean>(false);
const signature = ref(null);
const booking = ref(new Booking());

function cancel(): void {
  contractStore.$reset();
  router.back();
}

async function createContract(): Promise<void> {
  loading.value = true;
  try {
    const sign = signature.value!.save('image/jpeg');

    if (!sign) return;

    booking.value.sign = signature.value!.save('image/jpeg');

    await contractStore.create(booking.value.toJson());

    await router.push({ name: 'BookingEnd' });
  } catch (err) {
    console.error(err);
  }
  loading.value = false;
}

async function undoSign(): Promise<void> {
  signature.value.undo();
}

async function clearSign(): Promise<void> {
  signature.value!.clear();
}

onBeforeMount(() => {
  if (!bookingStore.booking) {
    cancel();
  }

  booking.value = bookingStore.booking!;
});
</script>

<template>
  <v-container class="">
    <v-sheet border="md"
             class="pa-6 mx-auto mb-5">
      <h4 class="text-h5 font-weight-bold mb-4 text-uppercase">Estas a solo un paso</h4>

      <p>
        Solo debes firmar en el recuadro que esta debajo para afirmar que estas de acuerdo con las
        condiciones previamente mostradas.</p>
    </v-sheet>

    <v-sheet class="pa-3 ">
      <v-container>

        <v-btn-toggle
          class="mb-5 float-end"
          variant="outlined"
          divided
        >
          <v-btn icon="mdi-arrow-u-left-top" v-on:click="undoSign"/>
          <v-btn icon="mdi-window-close" v-on:click="clearSign" />
        </v-btn-toggle>

        <Vue3Signature ref="signature"
                       h="400px"
                       class="border-thin" />

        <v-checkbox
          v-model="booking.authorization"
          label="Acepto compartir los datos para vivir la experiencia Travel Expo de Econorent">

        </v-checkbox>
      </v-container>

    </v-sheet>

    <v-row class="my-10">

      <v-col cols="12" md="6" sm="4">
      </v-col>

      <v-col cols="12" md="3" sm="4">
        <v-btn rounded="xl" size="x-large" :disabled="loading" block v-on:click="cancel">
          Cancelar
        </v-btn>
      </v-col>

      <v-col cols="12" md="3" sm="4">
        <v-btn color="primary"
               rounded="xl"
               size="x-large"
               :loading="loading"
               v-on:click="createContract"
               block>
          Crear contrato
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
