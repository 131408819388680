import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, {
        block: "",
        to: {name: 'BookingFind'}
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Apertura de contratos ")
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        block: "",
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cierre de contratos ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}