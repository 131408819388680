import { defineStore } from 'pinia';
import axios from 'axios';
import Booking from '@/models/Booking';

const useContractStore = defineStore('contract', {
  state: () => ({
    booking: null as Booking | null,
    generalConditions: null as [] | null,
  }),
  actions: {
    async calculate(body: any): Promise<void> {
      const response = await axios.post('contract/calculate', body);

      const { condiciones_generales: generalConditions, booking } = response.data;

      this.generalConditions = generalConditions;
      this.booking = Booking.fromJSON(booking);
    },
    async create(body: any): Promise<void> {
      await axios.post('contract', body);
    },
    $reset(): void {
      this.booking = null;
      this.generalConditions = null;
    },
  },
});

export default useContractStore;
