import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/Home/HomeView.vue';
import BookingFind from '@/views/Booking/BookingFindView/BookingFindView.vue';
import BookingEdit from '@/views/Booking/BookingEditView/BookingEditView.vue';
import BookingPreviewContract from '@/views/Booking/BookingPreviewContract/BookingPreviewContract.vue';
import BookingSignContract from '@/views/Booking/BookingSignContract/BookingSignContract.vue';
import BookingEndView from '@/views/Booking/BookingEndView/BookingEndView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      free: false,
      transition: 'slide-left',
    },
  },
  {
    path: '/booking',
    meta: {
      free: false,
      transition: 'slide-left',
    },
    children: [
      {
        path: 'find',
        name: 'BookingFind',
        component: BookingFind,
        meta: {
          free: false,
          transition: 'slide-left',
        },
      },
      {
        path: 'edit',
        name: 'BookingEdit',
        component: BookingEdit,
        meta: {
          free: false,
          transition: 'slide-left',
        },
      },
      {
        path: 'preview-contract',
        name: 'BookingPreviewContract',
        component: BookingPreviewContract,
        meta: {
          free: false,
          transition: 'slide-left',
        },
      },
      {
        path: 'sign-contract',
        name: 'BookingSignContract',
        component: BookingSignContract,
        meta: {
          free: false,
          transition: 'slide-left',
        },
      },
      {
        path: 'booking-end',
        name: 'BookingEnd',
        component: BookingEndView,
        meta: {
          free: false,
          transition: 'slide-left',
        },
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // const userStore = useUSerStore();
  // const agencyStore = useAgencyStore();
  //
  // const isFreeRoute = to.matched.some((record) => record.meta.free);
  // const isAuthenticated = userStore.data;
  // const hasSelectedAgency = agencyStore.agency;
  //
  // if (isFreeRoute) {
  //   next();
  // } else if (isAuthenticated) {
  //   if (hasSelectedAgency) {
  //     next();
  //   } else if (to.name === 'AgencySelection') {
  //     next();
  //   } else {
  //     next({ name: 'AgencySelection' });
  //   }
  // } else if (to.name === 'Login') {
  //   next();
  // } else {
  //   next({ name: 'Login' });
  // }

  next();
});

export default router;
