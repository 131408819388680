<template>
  <v-container>
    <v-card title="RESERVAS PENDIENTES"
            subtitle="Listado de reservas por abrir contratos"
            rounded="0">

      <v-divider></v-divider>

      <v-card-text class="px-10">
        <v-list lines="two">
          <v-list-item
            v-for="boo in bookings"
            :key="boo.id"
            :subtitle="boo.identification"
            :title="`${boo.name} ${boo.lastname}`" >
            <template v-slot:prepend>
              <v-avatar color="grey-lighten-1">
                <v-icon color="white">mdi-file-document-edit-outline</v-icon>
              </v-avatar>
            </template>

            <template v-slot:append>
              <v-btn
                size="x-large"
                color="red-lighten-1"
                icon="mdi-trash-can-outline"
                variant="text"
                v-on:click="deleteBooking(boo.id)"
              ></v-btn>
              <v-btn
                size="x-large"
                color="grey-lighten-1"
                icon="mdi-file-document-edit-outline"
                variant="text"
                v-on:click="selectBooking(boo.id)"
              ></v-btn>
            </template>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>

    <v-overlay
      :model-value="loading"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script setup lang="ts">
import useBookingStore from '@/store/booking.store';
import { onBeforeMount, ref } from 'vue';
import BookingInterface from '@/interfaces/booking.interface';
import { useRouter } from 'vue-router';

const router = useRouter();
const bookingStore = useBookingStore();

const bookings = ref<BookingInterface[]>([]);
const loading = ref(false);

onBeforeMount(async (): Promise<void> => {
  loading.value = true;
  await bookingStore.fetchBookings();

  bookings.value = bookingStore.bookings!;
  loading.value = false;
});

async function selectBooking(id: number): Promise<void> {
  loading.value = true;
  await bookingStore.fetchBooking(id);

  await router.push({ name: 'BookingEdit' });
  loading.value = false;
}

async function deleteBooking(id: number): Promise<void> {
  loading.value = true;

  await bookingStore.deleteBooking(id);

  bookings.value = bookingStore.bookings!;

  loading.value = false;
}
</script>

<style src="./BookingFindView.style.css" scoped />
