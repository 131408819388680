import { defineStore } from 'pinia';
import Booking from '@/models/Booking';
import axios from 'axios';

const useBookingStore = defineStore('booking', {
  state: () => ({
    booking: null as Booking | null,
    bookings: null as Booking[] | null,
  }),
  actions: {
    async fetchBooking(id: number): Promise<void> {
      const response = await axios.get(`booking/${id}`);

      this.booking = Booking.fromJSON(response.data);
    },
    async fetchBookings(): Promise<void> {
      const response = await axios.get('booking');

      this.bookings = response.data;
    },
    async deleteBooking(id: number): Promise<void> {
      await axios.delete(`booking/${id}`);

      await this.fetchBookings();
    },
    $reset() {
      this.booking = null;
      this.bookings = null;
    },
  },
});

export default useBookingStore;
