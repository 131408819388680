<template>
  <v-container>
    <v-form v-model="form" @submit.prevent="onSubmit">

      <v-card class="mb-5">
        <v-card-item prepend-icon="mdi-account-card">
          <v-card-title>
            Cliente / Conductor
          </v-card-title>
        </v-card-item>

        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col cols="12" md="6" xs="12">
                <v-text-field disabled
                              v-model="booking.name"
                              density="compact"
                              label="Nombre (s)"
                              variant="outlined"
                              prepend-icon="mdi-account" />
              </v-col>
              <v-col cols="12" md="6" xs="12">
                <v-text-field disabled
                              v-model="booking.lastname"
                              density="compact"
                              label="Apellido (s)"
                              variant="outlined"
                              prepend-icon="mdi-account" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" xs="12">
                <v-text-field disabled
                              v-model="booking.identification"
                              density="compact"
                              label="R.U.T"
                              variant="outlined"
                              prepend-icon="mdi-card-account-details"/>
              </v-col>
              <v-col cols="12" md="6" xs="12">
                <v-text-field v-model="birthdate"
                              density="compact"
                              label="Fecha de nacimiento"
                              variant="outlined"
                              prepend-icon="mdi-cake-variant"/>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" xs="12">
                <v-text-field v-model="booking.email"
                              density="compact"
                              label="Email"
                              variant="outlined"
                              prepend-icon="mdi-email"
                              :rules="rules.email"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

      </v-card>

      <v-card class="mb-5">
        <v-card-item>
          <v-card-item prepend-icon="mdi-car">
            <v-card-title>
              Información del vehículo
            </v-card-title>
          </v-card-item>

        </v-card-item>

        <v-card-text>
          <v-text-field v-model="booking.auto"
                        density="compact"
                        label="Código"
                        variant="outlined" />

          <h2 class="text-h6 mb-2">Nivel de combustible</h2>
          <v-chip-group
            :rules="rules.fuel"
            v-model="booking.fuel"
            selected-class="text-deep-purple-accent-4"
            mandatory>
            <v-chip v-for="val in gui.combustibleLevels"
                    :key="val"
                    :text="val"
                    :value="val"
                    variant="outlined"
                    size="x-large"
                    rounded
            />
          </v-chip-group>
          {{ full }}
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-item>
          <v-card-title>
            Otros datos de la reserva
          </v-card-title>
        </v-card-item>

        <v-card-text>
          <v-text-field v-model="dateOut"
                        density="compact"
                        label="Fecha de salida"
                        variant="outlined"
                        disabled />

          <v-text-field v-model="booking.agency"
                        density="compact"
                        label="Sucursal de salida"
                        variant="outlined"
                        disabled />

          <v-text-field v-model="dateIn"
                        :rules="rules.dateIn"
                        density="compact"
                        label="Fecha de devolución"
                        variant="outlined" />

        </v-card-text>
      </v-card>

      <v-row class="my-10">
        <v-col md="6" />
        <v-col md="3">
          <v-btn rounded="xl" size="x-large" :disabled="loading" block v-on:click="cancel">
            Cancelar
          </v-btn>
        </v-col>
        <v-col md="3">
          <v-btn color="primary" type="submit" rounded="xl" size="x-large" :loading="loading" block>
            Calcular
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

  </v-container>
</template>

<script lang="ts" setup>
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import useBookingStore from '@/store/booking.store';
import Booking from '@/models/Booking';
import { onBeforeMount, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import useContractStore from '@/store/contract.store';

const router = useRouter();
const bookingStore = useBookingStore();
const contractStore = useContractStore();
const booking = ref<Booking>(new Booking({}));
const gui = reactive({
  combustibleLevels: [
    'E',
    '1/8',
    '1/4',
    '3/8',
    '1/2',
    '5/8',
    '3/4',
    '7/8',
    'F',
  ],
});
const loading = ref<boolean>(false);
const form = ref<boolean>(false);
const birthdate = ref<string | null>(null);
const dateOut = ref<string | null>(null);
const dateIn = ref<string | null>(null);
const full = ref('');

const rules = {
  email: [],
};

function cancel(): void {
  bookingStore.$reset();
  router.back();
}

async function onSubmit(): Promise<void> {
  if (!form.value) return;

  try {
    loading.value = true;

    setTimeout(async (): Promise<void> => {
      loading.value = false;
      await contractStore.calculate(booking.value.toJson());
      await router.push({ name: 'BookingPreviewContract' });
    }, 500);
  } catch (e) {
    console.error(e);
  }
}

onBeforeMount(async (): Promise<void> => {
  if (!bookingStore.booking) {
    cancel();
  }

  if (bookingStore.booking) {
    booking.value = bookingStore.booking;
    birthdate.value = dayjs(booking.value.birthdate).format('DD-MM-YYYY');

    let [hours, minutes] = booking.value.timeOut;
    dateOut.value = dayjs(booking.value.dateOut).set('hours', hours).set('minutes', minutes)
      .format('DD-MM-YYYY hh:mm a');

    [hours, minutes] = booking.value.timeIn;
    dateIn.value = dayjs(booking.value.dateIn).set('hours', hours).set('minutes', minutes)
      .format('DD-MM-YYYY hh:mm a');
  }
});
</script>
