/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import axios from 'axios';
import vuetify from '@/plugins/vuetify';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './assets/scss/custom.scss';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

createApp(App).use(vuetify).use(createPinia())
  .use(router)
  .mount('#app');
